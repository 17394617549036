import Vue from "vue";
import VueRouter from "vue-router";
import Container from "../components/Container.vue";
import { authRoute, authGuard } from "../auth-module";
import { adminRoutes } from "../admin-module";
import { storeRoutes } from "../lt/store-module";
import { logisticRoutes } from "../logistic-module";
import { dentalClinicRoutes } from "../dental-clinic-module";

Vue.use(VueRouter);

const routes = [
  authRoute,
  {
    path: "/",
    component: Container,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        redirect: "/dental-clinic/agenda"
      },
      {
        path: "/profile",
        component: () => import("../components/ProfilePage.vue")
      },
      adminRoutes,
      storeRoutes,
      logisticRoutes,
      dentalClinicRoutes,
      {
        path: "reports/quotes-fs",
        component: () => import("../dental-clinic-module/reports/QuoteFullScreen.vue"),
      }
    ]
  },

  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue")
  },
  {
    path: "/upload-files",
    component: () => import("../components/UploadFiles.vue")
  }
];

const router = new VueRouter({
  routes
});

/** @Guards */
router.beforeEach(authGuard);

export default router;
